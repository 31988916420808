<template>
  <div style="padding-top: 10px">
    <v-card>
      <v-card-title>
        Отчеты
        <v-btn x-small color="secondary" dark @click.prevent="exportDrom"
          >Экспорт Drom</v-btn
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-img
            :src="`${serverURI}/images/preview/${imageUrl(item)}`"
            :lazy-src="`${serverURI}/images/preview/${imageUrl(item)}`"
            max-width="128"
            class="grey lighten-2"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { REPORT_TAG, ITEM_TYPE } from '../../utils/mainConsts';

export default {
  props: {
    // Название справочника. От него будет зависить какие методы будут вызываться
    data: {
      type: String,
      default: REPORT_TAG.goods
    }
  },
  data: () => ({
    listType: ITEM_TYPE.report,
    search: '',
    selected: [],
    payloadItems: [],
    payloadHeaders: [],
    items: [],
    headers: []
  }),
  watch: {
    payloadItems(value) {
      this.items = value;
    },
    payloadHeaders(value) {
      this.headers = value;
    }
  },
  computed: {
    ...mapState(['serverURI'])
  },
  created() {
    this.update(this.data);
  },
  methods: {
    ...mapMutations(['setMessageData']),

    async exportDrom() {
      const res = await fetch('http://localhost:3000/csv', {
        method: 'GET'
      });
      // CHROME
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'drom.csv';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); // afterwards we remove the element again
      // console.log(res);
    },
    // Проверяет если изображений много, то в список попадет только первое.
    // Если одно, то оно и попадет
    imageUrl: item => {
      return Array.isArray(item.image) ? item.image[0].fileName : item.fileName;
    },

    async update(tag) {
      const res = await this.$store.dispatch('uniFetchAll', {
        name: this.data
      });
      this.payloadItems = res.map(el => {
        return {
          ...el,
          id: el._id
        };
      });
      this.payloadHeaders = [...this.$store.getters.allHeaders(this.data)];
    }
  }
};
</script>

<style></style>
